import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider,
    Grid,
    LinearProgress,
    adaptV4Theme,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Cancelled from '../../res/images/cancelled.png'
import Complete from '../../res/images/completed.png'
import Incomplete from '../../res/images/incomplete.png'
import PendingEpicUpdate from '../../res/images/pending_epic_update.png'
import Closed from '../../res/images/closed.png'
import Controls from '../controls/Controls'
import {useDispatch, useSelector } from 'react-redux';
import { dashboardCaseData, dashboardToggle, getLoading, isDashboardBl } from '../../reducers/DashboardReducer';
import moment from 'moment';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useHistory } from "react-router-dom";
import { updateLoaderState, toggleToMonthView } from '../../actions/DashboardAction'
import MonthCalendarView from "../dashboard/MonthCalendarView";
import Loader from "../common/Loader";
import { COMMON_DATE_FORMAT, LBL_TIME, LBL_PATIENT_NAME, LBL_DOB,LBL_PROCEDURA_LIST, LBL_PROCEDURE_TIME, LBL_LOCATION, LBL_MEDTEL_CASE_ID,
    LBL_PROCEDURE_UNIT, LBL_CONFIRMATION_HASH, LBL_STATUS, LBL_PROCEDURE_DATE, DASHBOARD_DEFAULT_ITEM_PER_PAGE, DEFAULT_PER_PAGE_OPTIONS } from '../../constants/GlobalConstants';
import AttachFileIcon from '@mui/icons-material/AttachFile';


const MappingKeys = {
    PatientName : "PatientFullName",
    DocumentCount :"DocumentCount",
    PatientDOB : "PatientDOB",
    PrimarySurgeonName: "PrimarySurgeonName",
    HospitalMRN: "HospitalMRN",
    CaseId :"CaseId",
    ProcedureUnitName:"ProcedureUnitName",
    EncounterId :"EncounterId",
    ConfirmationNumber :"ConfirmationNumber",
    ServiceLineName :"ServiceLineName",
    CaseStatusId :"CaseStatusId",
    SurgicalLocationName :"SurgicalLocationName",
    BookingSheetId :"BookingSheetId",
    BookingSheetName :"BookingSheetName",
    ProcedureDate : "ProcedureDate",
    ProcedureTime: "ProcedureTime"  
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fafafc',
        margin: `0px ${theme.spacing(2)}`,

    },
    toggleSection: {
        margin: `0px ${theme.spacing(2)}`,
    },

    searchSection: {
        margin: `0px 0px 0px 70%`
    },

    viewSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        float: "left"
    },
    marginOverall: {
        margin: ".5% ",
    },


    dayViewColor: {
        margin: `0px ${theme.spacing(2)}`,
        color: '#2b94c9',
    },

    caseStatusIcon: {
        height: theme.spacing(3),
        width: theme.spacing(3),
        verticalAlign: 'middle'
    },
    DataTableWraper: {
        display: 'flex',
        flexDirection: 'column',

    }
}))

const getMuiTheme = () => createTheme(adaptV4Theme({
    overrides: {
        MUIDataTable: {
            root: {
                maxWidth: "100%",
                flexBasis: "100%"
            },
            paper: {
                boxShadow: "none"
            },
        },
        MUIDataTableBodyRow: {
            root: {
                '&:nth-child(odd)': {
                    backgroundColor: '#FFF'
                }
            }
        },
        MuiTableCell: {
            head: {
                backgroundColor: "#2b94c9 !important",
                color: "#FFF !important",
                fontSize: '0.875rem',
                '&:MUIDataTableHeadCell-sortActive': {
                    color: 'white'
                }
            },
            body: {
                fontSize: '0.875rem'
            }
        },

    }
}))

function DataTable() {
    const classes = useStyles();
    const tableBodyHeight = 'auto'
    const history = useHistory();
    const dispatch = useDispatch()
    const monthToggle = useSelector(dashboardToggle)
    var loading  = useSelector(getLoading);
    const dashboardCases= useSelector(dashboardCaseData)
    const dahsboardSearchBl= useSelector(isDashboardBl)

    const handleChange = (e) => {
        dispatch(updateLoaderState(e.target.checked))
        dispatch(toggleToMonthView(e.target.checked))
       
    };

    //Set grid/datatable columns
    const columns = [
        {
            id: MappingKeys.CaseStatusId,
            name: LBL_STATUS,
            options: {
                filter: false,
                display: true,
            }
        },
        {
            id: MappingKeys.ProcedureTime,
            name: LBL_TIME,
            options: {
                filter: false,
                display: true,
            }
        },
        {
            id: MappingKeys.PatientName,
            name : LBL_PATIENT_NAME,
            options: {
                filter: false,
                display: true,
            }
        },
        {
            id: MappingKeys.PatientDOB,
            name: LBL_DOB,
            options: {
                filter: true,
                display: true,                
                customBodyRender: value =>
                moment(new Date(value)).format(COMMON_DATE_FORMAT) 
            }
        },
        {
            id: MappingKeys.PrimarySurgeonName,
            name: LBL_PROCEDURA_LIST,
            options: {
                filter: false,
                display: true,
            }
        },
        {
            id: MappingKeys.ProcedureDate,
            name: LBL_PROCEDURE_DATE,
            options: {
                filter: true,
                sort: true,
                sortDirection: 'desc',
                customBodyRender: value =>
                moment(new Date(value)).format(COMMON_DATE_FORMAT)
            }
        },
        {
            id: MappingKeys.SurgicalLocationName,
            name: LBL_LOCATION,
            options: {
                filter: false,
                display: true,
            }
        },
        {
            id: MappingKeys.CaseId,
            name: LBL_MEDTEL_CASE_ID,
            options: {
                filter: true,
                sort: true
            }
        },
        {
            id: MappingKeys.ProcedureUnitName,
            name: LBL_PROCEDURE_UNIT,
            options: {
                filter: false,
                display: true,
            }
        },
        {
            id: MappingKeys.ConfirmationNumber,
            name: dashboardCases?.Headers?.find(item=> item.FieldMappingId === MappingKeys.ConfirmationNumber)?.FieldName || LBL_CONFIRMATION_HASH,
            options: {
                filter: false,
                display: true,
            }
        },
        {
            id: MappingKeys.DocumentCount,
            name: <AttachFileIcon fontSize="small" />,
            options: {
                filter: false,
                display: true,
            }
        }]

    const options = {
        filter: false,
        filterType: "textField",
        tableBodyHeight,
        search: false,
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        sort:true,
        rowsPerPage: DASHBOARD_DEFAULT_ITEM_PER_PAGE,
        rowsPerPageOptions: DEFAULT_PER_PAGE_OPTIONS,
        setRowProps: row => {
            return {
                style: { cursor: "pointer" }
            };
        },
        expandableRows: false,
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            return (
                <TableRow>
                    <TableCell colSpan={colSpan}>
                        Custom expandable row options.
                    </TableCell>
                </TableRow>
            );
        },
        onRowClick: (rowData, rowMeta) => {
            let caseId =  dashboardCases.Items[rowMeta.dataIndex].Case.CaseId;          
            history.push(`case/${parseInt(caseId)}/PatientInformation`);
            
        },
        textLabels: {
            body: {
                noMatch: "Sorry, there is no matching data to display"
            }
        }
    }

    const checkCaseStatus = (statusId) => {
        switch (statusId) {
            case 1:
                return Closed;
                break;
            case 2:
                return Cancelled;
                break;
            case 3:
                return Complete;
                break;
            case 4:
                return Incomplete;
                break;
            case 9:
                return PendingEpicUpdate;
                break;
  
        }
    }

    const CasesResults = [];
    if (dashboardCases && dashboardCases.Items) {
      //  var sortedByCaseId = dashboardCases.Items.sort((a, b) => a.Case.CaseId - b.Case.CaseId);
       // var sortedByProcedureDateTime = sortedByCaseId.sort((a, b) => moment(b.Case.ProcedureDateTime).format('MM/DD/YYYY') - moment(a.Case.ProcedureDateTime).format('MM/DD/YYYY'));
        dashboardCases.Items.forEach(element => {
            var caseListData = []
            caseListData.push(<img src={checkCaseStatus(element.Case.CaseStatusId)} className={classes.caseStatusIcon} alt="" />)
            caseListData.push(element.Case.ProcedureTime)
            caseListData.push(element.Patient.PatientFullName)
            caseListData.push(moment(element.Patient.PatientDOB).toDate().getTime())
            caseListData.push(element.Case.PrimarySurgeonName)
            caseListData.push(moment(element.Case.ProcedureDate).toDate().getTime())
            caseListData.push(element.Case.SurgicalLocationName)
            caseListData.push(parseInt(element.Case.CaseId))
            caseListData.push(element.Case.ProcedureUnitName)
            caseListData.push(element.Case.ConfirmationNumber)
            caseListData.push(element.Document.DocumentCount)
            CasesResults.push(caseListData);
        });
        
        //Hide column from grid if does not come according permission in header response
        columns.forEach((col) => {
            if(!dashboardCases.Headers.some(item=> item.FieldMappingId === col.id)) {
                col.options.display = false;
            }
        })
    }
 
    if (dahsboardSearchBl) {       
        return (
            <React.Fragment>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={getMuiTheme()}>
                        {loading ? <Loader loading={loading}/> :
                            <MUIDataTable
                            data={CasesResults}
                            columns={columns}
                            options={options}
                            className={classes.root}
                        />
                        
                        }
                     
                    </ThemeProvider>
                </StyledEngineProvider>
            </React.Fragment>
        );
    }
    else {
     
        {
            return (
                <React.Fragment>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={getMuiTheme()}>
                            <div className={classes.DataTableWraper}>
                                <div className={classes.marginOverall}>
                                    <div className={classes.viewSection}>
                                        <label className={classes.dayViewColor}>Day View</label>
                                        <Controls.Toggle handleChange={handleChange} checked={monthToggle} />
                                        <label className={classes.toggleSection}>Month View</label>
                                    </div>
                                </div>

                                {monthToggle 
                                    ?
                                    <div style={{ margin: "0 10px" }}>
                                        { loading ? <LinearProgress style={{ margin: "0 16px" }} /> : null}
                                   <MonthCalendarView />                                    
                                    </div>
                                    : <div >
                                        { loading ? <LinearProgress  style={{ margin: "0 16px" }}/> : null}
                                            <MUIDataTable
                                            data={CasesResults}
                                            columns={columns}
                                            options={options}
                                            className={classes.root}
                                            />                                                                                                           
                                    </div>
                                }
                            </div>

                        </ThemeProvider>
                    </StyledEngineProvider>
                </React.Fragment>
            );
        }


    }
}


export default DataTable;